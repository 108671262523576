export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) return

  const { setupIncomingBusinessUnit, setupIncomingService } = useAppointment()
  const { validateUuid } = useMarketplace()
  const companySlug = to.params.slug?.toString() || 'meevo'

  // Set default fallback route based on companySlug
  let defaultStep: any = { name: 'slug-appointment-new-step', params: { slug: companySlug, step: '1' } }
  if (companySlug === 'meevo') defaultStep = { name: 'meevo-appointment-new-step', params: { step: '1' } }

  // Do nothing if step is 1
  if (to.params.step === '1') return

  // Load service if step is 3
  if (to.params.step === '3' && to.query?.serviceUuid) {
    if (companySlug !== 'meevo') {
      if (!validateUuid(to.query.serviceUuid.toString(), 'service')) return navigateTo(defaultStep)
      return
    }
    if (!(await setupIncomingService(to.query.serviceUuid.toString()))) return navigateTo(defaultStep)
    return
  }

  // Load business unit if step is 2
  if (to.params.step === '2' && to.query?.businessUnitUuid) {
    if (companySlug !== 'meevo') {
      if (!validateUuid(to.query.businessUnitUuid.toString(), 'businessUnit')) return navigateTo(defaultStep)
      return
    }
    if (!(await setupIncomingBusinessUnit(to.query.businessUnitUuid.toString()))) return navigateTo(defaultStep)
    return
  }

  // Navigate to default step if no service or businessunit is given and server side rendered
  if (['2', '3', '4'].includes((to.params.step || '').toString())) return navigateTo(defaultStep)

  // Fallback
  return navigateTo(defaultStep)
})
